import React from 'react'
import ResumeData from './ResumeData'

function ResumeMain() {
    const heading={
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
      }
      const contain ={
        padding: 20,
        marginLeft: 30,
      }

  return (
    
      <div style={contain} className="contain">
      <h4 style={heading} className="head">Resumes</h4>
        <div>
          <ResumeData />
        </div>
       
    </div>
    
  )
}

export default ResumeMain
