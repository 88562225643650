import React from 'react'
import { Button } from 'react-bootstrap'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function MemberButton() {
   
  return (
    <div className='mb-4'>
        <Button variant="primary"> {WhatsAppIcon && <WhatsAppIcon type={WhatsAppIcon}/>} Add Member</Button>
        {/* <button style={button} type="button" class="btn btn-primary btn-lg">Submit</button> */}
    </div>
   
  )
}

export default MemberButton