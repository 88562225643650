import React, { useState } from "react";
import {NavLink } from "react-router-dom";
import "./NavBar.css";
import Image from "../../Assets/movidu.jpg"; 
import LogoutIcon from '@mui/icons-material/Logout';

function NavBar() {
  const [click, steClick] = useState(false);

  const handleClick = () => steClick(!click);
  const logo = {
    paddingBottom: 25
  };
  const text1 = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 800,
    // marginTop:-10
  };

 

  return (
    <>
      <div className="navbar">
        <NavLink
          exact
          to="/"
          //  activeClassName="active"
          className="nav-logo"
        >
          <img
            src={Image}
            class="img-fluid"
            alt="logo"
            height="80"
            width="100"
            style={logo}
          />
        </NavLink>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <NavLink
            exact
            to="/profile"
            className="nav-links"
            onClick={handleClick}
            style={text1}
          >
            <li class="nav-item d-flex " style={{ marginTop: -8 }}>
              
                <h6 style={{marginTop: 10}}>Ishan Srivastava</h6>
             
            </li>
          </NavLink>
          <NavLink
            exact
            to="/login"
            className="nav-links"
            onClick={handleClick}
            style={text1}
          >
            <li class="nav-item d-flex " style={{ marginTop: -8 }}>
              <div >
                <LogoutIcon />
              </div>
            </li>
          </NavLink>
        </ul>
        <div className="nav-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </div>
    </>
  );
}

export default NavBar;
