import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CKEditor from "react-ckeditor-component";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Demographics from './Demographics';
import YourDeliverables from './YourDeliverables';
import Finish from './Finish'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react";
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import Card from 'react-bootstrap/Card';
import VerifiedIcon from '@mui/icons-material/Verified';
import Button from 'react-bootstrap/Button';
import API from '../../../Route/api';
import Swal from 'sweetalert2';



const ckeditor = {
  marginTop: 30
}
const ck = {
  marginTop: 30,
  width: '50%'
}

const demographics = {
  marginTop: 30
}
const demographics2 = {
  marginTop: 60
}
const card = {
  padding: 50

}

const workshoptopic = {
  marginTop: 30
}

const icon = {
  fontSize: 70,
  color: '#8D0956',
  paddingBottom: 10,
}


function TabPanel(props: TabPanelProps) {


  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [visible, setVisible] = useState(false);
  const [age, setAge] = React.useState('');
  const [aged, setAged] = React.useState('');
  const [val, setVal] = React.useState(dayjs('2022-04-07'));
  const [project, setProject] = useState([])
  const [skills, setSkills] = React.useState([]);
  const [targetAgeGroup, settargetAgeGroup] = React.useState([]);
  const [region, setRegions] = React.useState([]);
  const [socioEconomicStatus, setSocioEconomicStatus] = React.useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  React.useEffect(() => {
    initiateData();
  }, []);

  const handleTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  const initiateData = () => {
    API.get('/projects/launch/initiate').then(res => {
      setSkills(res.data.data.skills);
      settargetAgeGroup(res.data.data.ageGroup)
      setRegions(res.data.data.regions)
      setSocioEconomicStatus(res.data.data.socioEconomicStatus)
    });
  }

  const handleAutoCompleteCheckbox = (event, value, name) => {
    setProject(
      prevState => ({
        ...prevState,
        [name]: value
      }));
  }
  const handleDateChange = (date, name) => {
    setProject(
      prevState => ({
        ...prevState,
        [name]: date
      }));
  }
  const handleCKEditor = (e, name) => {
    setProject(
      prevState => ({
        ...prevState,
        [name]: e.editor.getData()
      }));
  }
  const handleChange = (e) => {
    console.log(project)
    setProject(
      prevState => ({
        ...prevState,
        [e.target.name]: e.target.value
      })
    );
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append("project", JSON.stringify(project));
    API.post('projects/launch',project).then(res=>{
        if(res.data.code===200){
          Swal.fire({
              title: res.data.status,
              text: res.data.message,
              icon: 'success',
              confirmButtonText: 'OK'
          }).then((result) => {             
            window.location.href="/project";             
          })
        }
    }).catch(err=>{
      if(err.code==='ERR_BAD_REQUEST'){
          Swal.fire({
              title: err.response.data.status,
              text: err.response.data.message,
              icon: 'error',
              confirmButtonText: 'OK'
          })
        } 
    }) 
  }

  function a11yProps(index: number) {
    if (index === 3) {
      console.log(project)
    }
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <Box className="tabs" sx={{ borderBottom: 1, borderColor: 'divider', }}>
        <Tabs value={value} onChange={handleTab} aria-label="basic tabs example">
          <Tab label="Requirements" {...a11yProps(0)} />
          <Tab label="Demographics" {...a11yProps(1)} />
          <Tab label="Your Deliverables" {...a11yProps(2)} />
          <Tab label="Finish" {...a11yProps(3)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <div>
          <p><b>Project Type*</b></p>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={project?.company_project_type}
              label=""
              name="company_project_type"
              onChange={handleChange}
            >
              <MenuItem value={'Business'}>Business</MenuItem>
              <MenuItem value={'Technical'}>Technical</MenuItem>
              <MenuItem value={'Marketing'}>Marketing</MenuItem>
            </Select>
          </FormControl>
        </div>

        <TextField sx={{ marginTop: '30px', marginBottom: '30px' }} fullWidth label="Task Type *" value={project?.company_task} id="fullWidth" name="company_task" onChange={handleChange} />

        <p><b>Instructions (Do's & Don'ts) *</b></p>
        <CKEditor initData={<p>This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React.</p>} content={project?.company_project_instruction} name="company_project_instruction" events={{ 'change': (event) => handleCKEditor(event, 'company_project_instruction') }} />

        <p style={ckeditor}><b>Your Task (Mention in bullet points) *</b></p>
        <CKEditor initData={<p>This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React.</p>} content={project?.company_project_description} name="company_project_description" events={{ 'change': (event) => handleCKEditor(event, 'company_project_description') }} />

        <Box style={ck}
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <FormLabel id="demo-row-radio-buttons-group-label"><b>Do You Have Any Incentive Model? *</b></FormLabel>

            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
              <FormControlLabel type="radio" className="mx-2 mt-1" name="company_project_incentive" value="yes" {...project?.company_project_incentive === "yes" ? 'checked':''} onChange={() => handleChange, () => setVisible(true)} control={<Radio />} label="Yes" />
              <FormControlLabel type="radio" className="mx-2" name="company_project_incentive" value="no" {...project?.company_project_incentive === "no" ? 'checked':''} onChange={() => handleChange, () => setVisible(false)} control={<Radio />} label="No" />
            </RadioGroup>
            {visible &&
              <div className="form-group row">
                <div className="col-sm-12">
                  {/* <textarea type="text" className="form-control" id="msg" name="msg" placeholder='Duration' /> */}
                  <CKEditor initData={<p>This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React.</p>} content={project?.company_project_incentive_model} name="company_project_incentive_model" events={{ 'change': (event) => handleCKEditor(event, 'company_project_incentive_model') }} />
                </div>
              </div>
            }

          </div>

        </Box>

        <p style={ckeditor}><b>Link For Steps To Be Followed *</b></p>
        <TextField fullWidth label="Youtube Link *" id="fullWidth" value={project.company_project_youtube_link} name="company_project_youtube_link" onChange={handleChange} />

        <p style={ckeditor}><b>Start Date *</b></p>
        {/* <DateEndPicker/> */}
        <Stack component="form" noValidate spacing={3}>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            defaultValue="2017-05-24"
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            value={project?.company_project_start_date}
            name="company_project_start_date"
            onChange={handleChange}
          />
        </Stack>
        {/* Date Piker */}

        {/* End  */}

        <p style={ckeditor}><b>Expected End Date *</b></p>
        <TextField fullWidth label="Enter The Expected End Date *" id="fullWidth" value={project?.company_project_expected_end_date} name="company_project_expected_end_date" onChange={handleChange} />


      </TabPanel>

      {/* Start Demographics */}

      <TabPanel value={value} index={1}>
        <div>
          <p><b>Select Region *</b></p>
          {/* <Demographics /> */}
          <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={region}
            getOptionLabel={(option) => option.state}            
            name="company_project_regions"
            value={project?.company_project_regions}
            onChange={(event, value) => handleAutoCompleteCheckbox(event, value, 'company_project_regions')}
            renderInput={(params) => (
              <TextField {...params}
                sx={{ width: '100%' }}

              />

            )}
          // sx={{ width: '500px' }}
          />
        </div>
        <div style={demographics}>
          <FormLabel id="demo-row-radio-buttons-group-label"><b>Gender *  </b></FormLabel>

          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">

            <FormControlLabel value="Male" name="company_project_gender" {...project?.company_project_gender === "Male" ? 'checked':''} onChange={handleChange} control={<Radio />} label="Male" />

            <FormControlLabel value="Female" name="company_project_gender" {...project?.company_project_gender === "Female" ? 'checked':''} onChange={handleChange} control={<Radio />} label="Female" />
            <FormControlLabel value="Both" name="company_project_gender" {...project?.company_project_gender === "Both" ? 'checked':''} onChange={handleChange} control={<Radio />} label="Both" />
          </RadioGroup>


        </div>

        <div style={demographics}>
          <p><b>Market Segment Status *</b></p>
        <CKEditor initData={<p>This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React.</p>} content={project?.company_project_instruction} name="company_project_instruction" events={{ 'change': (event) => handleCKEditor(event, 'company_project_instruction') }} />

          {/* <Demographics /> */}
          {/* <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={socioEconomicStatus}
            getOptionLabel={(option) => option.name}           
            name="company_project_socio_economic_status"
            value={project?.company_project_socio_economic_status}
            onChange={(event, value) => handleAutoCompleteCheckbox(event, value, 'company_project_socio_economic_status')}
            renderInput={(params) => (
              <TextField {...params}
                sx={{ width: '100%' }}
              />

            )}
          // sx={{ width: '500px' }}
          /> */}
        </div>

        <div style={demographics2}>
          <p><b>Target Age Group *</b></p>
          {/* <Demographics /> */}
          <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={targetAgeGroup}
            getOptionLabel={(option) => option.name}
            
            name="company_project_target_age_group"


            value={project?.company_project_target_age_group}
            onChange={(event, value) => handleAutoCompleteCheckbox(event, value, 'company_project_target_age_group')}
            renderInput={(params) => (
              <TextField {...params}
                sx={{ width: '100%' }}

              />

            )}
          // sx={{ width: '500px' }}
          />

        </div>
      </TabPanel>

      {/* End of Start Demographics */}

      {/* Your Deliverables */}

      <TabPanel value={value} index={2}>
        {/* Your Deliverables */}


        {/* <YourDeliverables /> */}

        <Box
          component="form" noValidate autoComplete="off">
          <div>
            <p><b>Project Type*</b></p>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={project?.company_project_workshop_type}
                label=""
                name="company_project_workshop_type"
                onChange={handleChange}
              >
                <MenuItem value="Business">Business</MenuItem>
                <MenuItem value="Technical">Technical</MenuItem>
                <MenuItem value="Marketing">Marketing</MenuItem>
              </Select>
            </FormControl>
          </div>

          <p style={workshoptopic}><b>Project Topic*</b></p>
          <TextField fullWidth id="outlined-basic" placeholder="Workshop Topic*"value={project?.company_project_workshop_topic} name="company_project_workshop_topic" onChange={handleChange} />

          {/* Date and Time */}
          <p style={workshoptopic}><b>Date & Time *</b></p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              defaultValue={dayjs('2022-04-17T15:30')}
              onChange={(date) => { handleDateChange(new Date(date).toLocaleString("en-US"), 'company_project_workshop_dateandtime') }}
              value={project?.company_project_workshop_dateandtime}
              renderInput={(props) => <TextField {...props} />}
              label="DateTimePicker"


            />
          </LocalizationProvider>

          {/* End of Date And Time */}

          <p style={workshoptopic}><b>Project Description*</b></p>
          <TextField fullWidth id="outlined-multiline-static" multiline rows={4} placeholder="Workshop Description*" value={project?.company_project_workshop_description} name="company_project_workshop_description" onChange={handleChange} />

          {/* Skill Gained */}
          <p style={workshoptopic}><b>Skill Gained*</b></p>
          <Autocomplete multiple id="checkboxes-tags-demo" options={skills} disableCloseOnSelect getOptionLabel={(option) => option.name} renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option.name}
            </li>
          )}
            style={{ width: 500 }}
            value={project?.company_project_workshop_skill_gained}
            onChange={(event, value) => handleAutoCompleteCheckbox(event, value, 'company_project_workshop_skill_gained')}
            renderInput={(params) => (
              <TextField {...params} label="Skill Gained" placeholder="Favorites" />
            )}
          />

          {/* End Skill Gained */}

          <p style={workshoptopic}><b>Merchandise/Insentives For Top Performers(if any)</b></p>
          <TextField fullWidth id="outlined-basic" placeholder="Merchandise For Top Performers(if any)" value={project?.company_project_workshop_merch} name="company_project_workshop_merch" onChange={handleChange} />

        </Box>


        {/* End of Your Deliverables */}

      </TabPanel>

      {/* End of Your Deliverables */}

      {/* Finish */}

      <TabPanel value={value} index={3}>

        {/* <Finish /> */}
        <Card style={card} className="text-center">
          <Card.Body >
            {/* {VerifiedIcon && <VerifiedIcon type={VerifiedIcon} style={icon} />} */}
            <Card.Title>Thank you</Card.Title>
            <Card.Text>
              You Are Just One Click Away
            </Card.Text>
            <Button variant="primary" onClick={handleSubmit}>Submit</Button>
          </Card.Body>
          {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
        </Card>

      </TabPanel>

      {/* End of Finish */}

    </Box>
  );
}