import React from 'react'
// import WorkshopTable from '../Control/WorkshopTable'
import ProjectData from '../ProjectLaunch/ProjectData'

function ProjectMain() {
  const heading={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  }
  const contain ={
    padding: 20,
    marginLeft: 30,
  }
  return (
    <div style={contain} className="contain">
        <h4 style={heading} className="mb-4">Project</h4> 
        <div>
          <ProjectData />
        </div>
    </div>
    
  )
}

export default ProjectMain