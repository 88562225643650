import React from "react";
import { Table } from "react-bootstrap";

function ActivityStatus(props) {
  const contain = {
    padding: 20,
    backgroundColor: "#303d48",
    margin: 10,
    borderRadius: 10,
    height: "90%"
  };
  const columns = {
    color: "#fff",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
  };
  const head = {
    backgroundColor: "rgba(62, 125, 199, 0.2)",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#fff",
  };
  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#fff",

  };
  const table = {
    height: "90%"
  }
  const getStatus = (status) => {
    var text = '';
    switch(parseInt(status)){
      case 1  : text = "Work In Progress";
                break;
      case 2  : text = "Submitted";
                break;
      case 3  : text = "Project Approved"; 
                break;
      case 4  : text = "Project Rejected"; 
                break;
      default : text = "TBD";
    }
    return text;
  }
  return (
    <div style={contain} className="container " >
      <h5 style={heading}>Activity Status</h5>
      <div className="table-responsive-sm overflow-auto scroll" style={table}>
        <Table style={{ marginRight: 10 }} className="table">
          <thead className="text-center align-content-center" style={head}>
            <tr>
              <th>#</th>
              <th>Project ID</th>
              <th>Name</th>
              <th>Activity Type</th>
              <th>Activity Status</th>
              <th>Approve/Reject</th>
            </tr>
          </thead>
          <tbody className="text-center align-content-center" style={columns}>
            {props.activity_status?.map((val,index)=>{
              return(
              <tr>
                <td>{index+1}</td>
                <td>{val.project_id}</td>
                <td>{val.student_name}</td>
                <td>{val.task_type}</td>
                <td>{getStatus(val.project_staus)}</td>
                <td>-</td>                
              </tr>
            )})}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default ActivityStatus;
