import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import VerifiedIcon from '@mui/icons-material/Verified';

const card ={
    padding: 50
 
}

const icon ={
    fontSize: 70,
    color: '#8D0956',
    paddingBottom:  10,
}

function HeaderAndFooterExample() {
  return (
    <Card style={card} className="text-center">
      <Card.Body >
      {VerifiedIcon && <VerifiedIcon type={VerifiedIcon} style={icon} />}
        <Card.Title>Thank you</Card.Title>
        <Card.Text>
        You Are Just One Click Away
        </Card.Text>
        <Button variant="primary">Submit</Button>
      </Card.Body>
      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
    </Card>
  );
}

export default HeaderAndFooterExample;