import React,{useState} from 'react'
import TextField from '@mui/material/TextField';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import API from '../../Route/api';
import Swal from 'sweetalert2';

const h5 = {
    backgroundColor: '#79065E',
    padding: '10px',
    color: '#fff',
}
const input = {
    marginTop: '30px',
}
const ChangePassword = () => {
    const [password_details,setPassword] =  useState([])
    const handlePassword = (e)=>{
        setPassword(
            prevState => ({
                ...prevState,
                [e.target.name]:e.target.value
            })
        )
    }
    const handlePasswordReset = (e) => {
        e.preventDefault();
        if(password_details.length === 0){
            setPassword(
                {
                    'old_password':'',
                    'password':'',
                    'password_confirmation':''
                }
            )
        }
        console.log(password_details);
        API.post('auth/user/password/update',password_details).then(res=>{
            if(res.data.code===200){
                Swal.fire({
                title: res.data.status,
                text: res.data.message,
                icon: 'success',
                confirmButtonText: 'OK'
                }).then((result) => {
                window.location.reload();
                });
            }      
        }).catch(err=>{
            if(err.code==='ERR_BAD_REQUEST'){
                Swal.fire({
                title: err.response.data.status,
                text: err.response.data.message,
                icon: 'error',
                confirmButtonText: 'OK'
                })
            } 
        });  
    };

    return (
        <div>

            {/* Change Password */}
            <center>
                <TabPanel value="2">
                    <Box className={"changepass"}>
                        <div style={h5}>
                            <h5>Change Password</h5>
                        </div>
                        <div style={input}>
                            <p>Enter Old Password</p>
                            <TextField fullWidth label="Enter Old Password"  name="old_password"  onChange={handlePassword} />
                        </div>
                        <div style={input}>
                            <p>New Password</p>
                            <TextField fullWidth label="Enter New Password"  name="password" onChange={handlePassword} />
                        </div>
                        <div style={input}>
                            <p>Confirm Password</p>
                            <TextField fullWidth label="Enter Confirm Password"  name="password_confirmation"  onChange={handlePassword} />
                        </div>
                        <div style={input}>
                            <button className='btn btn-primary' onClick={handlePasswordReset}>Change Password</button>
                        </div>
                    </Box>
                </TabPanel>
            </center>
            {/* End of Change Password */}
        </div>
    )
}

export default ChangePassword
