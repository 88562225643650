import React from 'react'
import styled from 'styled-components'
import Acco from './Acco'

const Contain = styled.div`
    background-color: #28313d;
    padding: 20px;
    border-radius: 10px;
    margin-left: 10px;
`
const Head = styled.h4`
    fontWeight: 600;
    color: #fff;
    ;
  
`
const QueriesDash = () => {
  return (
    <Contain>
        <div>
            <Head className="head">Queries</Head>
        </div>
        <Acco />
    </Contain>
  )
}

export default QueriesDash