import React from 'react'
import FaqData from './QueriesData'
import styled from 'styled-components'


const Contain = styled.div` 
padding: 10px 50px 10px 30px;
`
function QueriesMain() {
  return (
    <Contain>
        <h2>Queries</h2>

        <FaqData/>
    </Contain>
  )
}

export default QueriesMain