import React, { useEffect } from 'react'
import MemberButton from './MemberButton'
import { Table } from "react-bootstrap";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ManageMemberMain from '../ControlPanel/Control/ManageMemberMain'
import { Button } from 'react-bootstrap'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import API from '../../Route/api'
import "../../App.css"

function MemberMain() {
  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  }
  const contain = {
    padding: 20,
    marginLeft: 30,
  }
  const memberdata = {
    background: '#2B3844'
  }
  const table = {
    marginTop: -7,
  }
  const columns = {}
  const head = {
    backgroundColor: '#EEF0F6',
    // color: '#fff',
    fontWeight: 600,
  };
  const [members, setMembers] = React.useState([]);
  useEffect(() => {
    generateWhatsappLink();
    getMembers();
  }, []);
  const generateWhatsappLink = () => {
    const whatsappBtn = document.querySelector(".whatsappbtn")
    API.get('/company/link/generate/whatsapp').then(res => {
      console.log(res)
      whatsappBtn.setAttribute("href", res.data.data.url)
    });
  }

  const getMembers = () => {
    API.get('/company/members').then(res => {
      console.log(res)
      setMembers(res.data.data)
    });
  }
  return (
    <div style={contain} className="contain">
      <h4 style={heading} className="mb-4">Members</h4>
      <div>
        <div className='mb-4'>
          <Button variant="primary" className="whatsappbtn"> {WhatsAppIcon && <WhatsAppIcon type={WhatsAppIcon} />} Add Member</Button>
        </div>
      </div>
      <div>
        <div style={contain} className="contain">
          <h4 style={heading} className="mb-4">Manage Members</h4>
          <div>
            <div className='mb-4'>
              <Button variant="primary">Create User Role</Button>
              {/* <button style={button} type="button" class="btn btn-primary btn-lg">Submit</button> */}
            </div>
          </div>
          <div>
            <div>
              <div style={memberdata}>
                {/* <h5 style={addmember}>Members</h5> */}
                <div style={contain} className="container " >
                  <h3 style={heading}>Members</h3>
                  <div className="table-responsive-sm overflow-auto scroll" style={table}>
                    <Table style={table}>
                      <thead className="text-center align-content-center" style={head}>
                        <tr>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Created By</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="text-center align-content-center" style={columns}>
                        { members.map((member,index)=>{
                          return(
                          <tr>
                            <td>{member.name}</td>
                            <td>Marketing</td>
                            <td>Ongoing</td>
                            <td>
                              <button className="btn btn-success" onClick={() => {
                                window.location.pathname = "/action"
                              }}>{EditIcon && <EditIcon type={EditIcon} />}</button>
                              <button className="btn btn-danger" >{DeleteForeverIcon && <DeleteForeverIcon type={DeleteForeverIcon} />}</button>
                            </td>
                          </tr>
                          )
                        }) }                                          
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default MemberMain