import React, { useEffect } from "react";
import { Dropdown, Table } from "react-bootstrap";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import API from '../../../Route/api';
import Swal from 'sweetalert2';

function PostNewJobData() {
    React.useEffect(() => {
        initiateData();
    }, []);
    const [jobs, setJobs] = React.useState([]);
    const [jobStatus, setJobStatus] = React.useState({});
    const [open, setOpen] = React.useState(false);

    const initiateData = () => {
        API.get('/company/jobs').then(res => {
            setJobs(res.data.data);
        });
    }
    
    const handleStatusUpdate = (e,id) => {
        const data = {...jobStatus};
        data.id = id
        data.status = e.target.value;
        setJobStatus(data);
    }
    //Triggered when Job Status State Change is detected
    useEffect(() => { 
        console.log('Updated State', jobStatus)
        if(Object.keys(jobStatus).length>0){
        triggerJobStatusUpdate()
        }
    }, [jobStatus])
    
    const triggerJobStatusUpdate = () => {
        console.log(jobStatus);
        API.post('/company/jobs/status',jobStatus).then(res => {
            if (res.data.code === 200) {
                setOpen(false);
                Swal.fire({
                    title: res.data.status,
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {                    
                    window.location.href = "/postnewjob";
                })
            }
        }).catch(err => {
            if (err.code === 'ERR_BAD_REQUEST') {
                Swal.fire({
                    title: err.response.data.status,
                    text: err.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }
        })
    }

    const handleDelete = (id) =>{
        API.post('/company/jobs/delete/'+id).then(res => {
            if (res.data.code === 200) {
                setOpen(false);
                Swal.fire({
                    title: res.data.status,
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {                    
                    window.location.href = "/postnewjob";
                })
            }
        }).catch(err => {
            if (err.code === 'ERR_BAD_REQUEST') {
                Swal.fire({
                    title: err.response.data.status,
                    text: err.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }
        })
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        justifyContent: 'center',
        textAlign: 'center'
    };

    const contain = {
        padding: 20,
        backgroundColor: "#fff",
        margin: 10,
        borderRadius: 10,
        height: "90%"
    };
    const columns = {
        color: "#6C757D",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 400,
    };
    const head = {
        backgroundColor: "rgba(62, 125, 199, 0.2)",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
    };

    const table = {
        height: "90%"
    }
    const btn = {
        fontSize: 'small',
    }
    const dropdown = {
        padding: 5,
        backgroundColor: '#326834',
        color: '#fff',
        borderRadius: 3
    }

    return (
        <div style={contain} className="container " >
            {/* <h3 style={heading}>Status</h3> */}
            <div className="box">
                <h5><b>Posted jobs</b></h5>
                <p className="posted">Here is jobs that you have posted</p>
            </div>
            <div className="table-responsive-sm overflow-auto scroll" style={table}>

                <Table style={{ marginRight: 10 }} className="table">
                    <thead className="text-center align-content-center" style={head}>
                        <tr>
                            <th>#</th>
                            <th>Job Profile</th>
                            <th>End Date</th>
                            <th>Status</th>
                            <th>No. of Resumes</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody className="text-center align-content-center" style={columns}>
                        {jobs.map((job, index)=>{
                            return (
                            <tr>
                            <td>{parseInt(index)+1}</td>
                            <td>{job.job_name}</td>
                            <td>{job.closing_date}</td>
                            <td>
                                <Dropdown >
                                    <select style={dropdown} onChange={(e)=>handleStatusUpdate(e,job.id)} value={job.status}>
                                        <option value="1">Open</option>
                                        <option value="0">Close</option>
                                    </select>
                                </Dropdown>
                            </td>
                            <td>24</td>
                            <td>
                                <button style={btn} className="btn btn-success" onClick={() => {
                                    window.location.pathname = "/action"
                                }}>{EditIcon && <EditIcon type={EditIcon} sx={{ fontSize: 'medium' }} />}Edit</button>&nbsp;&nbsp;


                                <button style={btn} className="btn btn-danger" onClick={()=>setOpen(true)} >{DeleteForeverIcon && <DeleteForeverIcon type={DeleteForeverIcon} sx={{ fontSize: 'medium' }} />}Delete</button>
                                {/* Delete Button Popup */}
                                <Modal
                                    hideBackdrop
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="child-modal-title"
                                    aria-describedby="child-modal-description"
                                >
                                    <Box className="modalbox" sx={{ ...style, width: 500 }}>
                                        <h2 id="child-modal-title"><b>Are you sure?</b></h2>
                                        <p id="child-modal-description">
                                            You won't be able to revert this!
                                        </p>
                                        <button className="btn btn-primary" onClick={()=>handleDelete(job.id)}>Yes Delete it!</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-danger" onClick={()=>handleClose}>Cancle</button>

                                    </Box>
                                </Modal>
                                {/* End of Delete Button Popup */}
                            </td>
                        </tr>
                        )})}
                                                
                    </tbody>
                </Table>
            </div >
        </div >
    );
}

export default PostNewJobData;
