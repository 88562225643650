import React from 'react'
import styled from "styled-components";
import ProfileTab from './ProfileTab';

const Contain = styled.div`
padding: 20px 50px 20px 30px;
`

function ProfileMain() {
  return (
    <Contain>
      <div><h4><b>My Profile</b></h4></div>
      <ProfileTab/>
    </Contain>
  )
}

export default ProfileMain