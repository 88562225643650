import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as RiIcons from 'react-icons/ri';

import PeopleIcon from '@mui/icons-material/People';
import LaunchIcon from '@mui/icons-material/Launch';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import WorkIcon from '@mui/icons-material/Work';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListAltIcon from '@mui/icons-material/ListAlt';

export const SideNewData = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <AiIcons.AiFillHome />,
   
  },
  {
    title: 'Members',
    path: '/member',
    icon: <PeopleIcon />,
  },
  {
    title: 'Lauch Project',
    path: '/projectlaunch',
    icon: <LaunchIcon />
  },
  {
    title: 'All Project',
    path: '/project',
    icon: <AccountTreeIcon />
  },
  // {
  //   title: 'Queries',
  //   path: '/queries',
  //   icon: <QuestionAnswerIcon />,


  // },
  {
    title: 'Jobs',
    path: '',
    icon: <WorkIcon />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Dashboard',
        path: '/jobdetails',
        icon: <DashboardIcon />
      },
      {
        title: 'Jobs',
        path: '/postnewjob',
        icon: <WorkIcon />
      },
      {
        title: 'Resume',
        path: '/resume',
        icon: <AssignmentIcon />
      },
      {
        title: 'Shortlist',
        path: '/shortlist',
        icon: <ListAltIcon />
      }

    ]
  }
];
