import React from 'react'
import { Button } from 'react-bootstrap'
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function ManageMemberButton() {
   
  return (
    <div className='mb-4'>
        <Button variant="primary">Create User Role</Button>
        {/* <button style={button} type="button" class="btn btn-primary btn-lg">Submit</button> */}
    </div>
   
  )
}

export default ManageMemberButton