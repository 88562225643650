import React from 'react'
// import Alltable from './Alltable'
import LoginData from './LoginData'

function LoginMain() {
 
  const contain ={
    padding: 20,
    marginLeft: 30,
  }
  return (
    <div style={contain} className="contain">
        {/* <h4 style={heading} className="mb-4">All Project's</h4>  */}
        <div>
          <LoginData />
        </div>
    </div>
    
  )
}

export default LoginMain;