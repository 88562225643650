import React from 'react';
// import Workshop from './Workshop';
import ManageMembers from './ManageMembers';
import "../../App.css"

function ControlMain() {
  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  }
  const contain = {
    padding: 20,
    marginLeft: 30,
  }
  return (
    <div className="row contain" style={contain}>
      <h4 style={heading} className="head">Control Panel</h4>
      <div className='row m-0'>
        <div className='col-md-6'>
          <ManageMembers />
        </div>
        {/* <div className='col-md-4'></div> */}
        <div className='col-md-6'>
          {/* <div>
            <Workshop />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ControlMain
