import React from 'react'
import { Table } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';



function CreateUserRole() {
    const memberdata = {
        background: '#2B3844'
    }
    const heading = {
        background: '#EEF0F6',
        padding: 15,
        // color: '#fff',
        fontWeight: 700,
    }
    const contain = {
        backgroundColor: "#fff",
    };
    const columns = {
        // color: "#fff",
        // fontFamily: "Montserrat",
        // fontStyle: "normal",
        // fontWeight: 400,
    };
    const head = {
        backgroundColor: '#EEF0F6',
        // color: '#fff',
        fontWeight: 600,
    };

    const table = {
        marginTop: -7,
        paddingTop: 10
    }
    const button = {
        marginTop: 10,
    }
    return (
        <div>
            <div style={memberdata}>
                {/* <h5 style={addmember}>Members</h5> */}
                <div style={contain} className="container " >
                    <h3 style={heading}>Members <p style={button}> <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Set User State
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Active</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Inactive</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown></p></h3>
                    <div className="table-responsive-sm overflow-auto scroll" style={table}>
                        <Table style={table}>
                            <thead className="text-center align-content-center" style={head}>
                                <tr>
                                    <th>Page Name</th>
                                    <th>Create</th>
                                    <th>Read</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody className="text-center align-content-center" style={columns}>
                                <tr>
                                    <td>User</td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                </tr>
                                <tr>
                                    <td>Project's</td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                </tr>
                                <tr>
                                    <td>Admin</td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                </tr>
                                <tr>
                                    <td>HR</td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                    <td><input type="checkbox" /> </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default CreateUserRole