import React from 'react'
import WorkshopTable from '../Control/WorkshopTable'

function WorkshopMain() {
  const heading={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  }
  const contain ={
    padding: 20,
    marginLeft: 30,
  }
  return (
    <div style={contain} className="contain">
        <h4 style={heading} className="mb-4">Workshops</h4> 
        <div>
          <WorkshopTable />
        </div>
    </div>
    
  )
}

export default WorkshopMain