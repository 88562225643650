import React from 'react'
import { Table } from "react-bootstrap";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function ManageMemberData() {
    const memberdata={
        background: '#2B3844'
    }
   const heading={
    background: '#EEF0F6',
    padding: 15,
    // color: '#fff',
    fontWeight: 700,
   }
   const contain = {
    backgroundColor: "#fff",
  };
  const columns = {
    // color: "#fff",
    // fontFamily: "Montserrat",
    // fontStyle: "normal",
    // fontWeight: 400,
  };
  const head = {
    backgroundColor: '#EEF0F6',
    // color: '#fff',
    fontWeight: 600,
  };

  const table = {
    marginTop: -7,
  }
  return (
    <div>
        <div style={memberdata}>
            {/* <h5 style={addmember}>Members</h5> */}
            <div style={contain} className="container " >
                <h3 style={heading}>Members</h3>
      <div className="table-responsive-sm overflow-auto scroll" style={table}>
        <Table style={table}>
          <thead className="text-center align-content-center" style={head}>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Created By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="text-center align-content-center" style={columns}>
            <tr>
              <td>Ishan</td>
              <td>Marketing</td>
              <td>Ongoing</td>
              <td>
              
              <button className="btn btn-success" onClick={() => {
            window.location.pathname = "/action"}}>{EditIcon && <EditIcon type={EditIcon}/>}</button>
          
              <button className="btn btn-danger" >{DeleteForeverIcon && <DeleteForeverIcon type={DeleteForeverIcon}/>}</button>
              
               </td>
            </tr>
            <tr>
              <td>Shashank</td>
              <td>Development</td>
              <td>Ongoing</td>
              <td>
              <button className="btn btn-success" onClick={() => {
            window.location.pathname = "/action"}}>{EditIcon && <EditIcon type={EditIcon}/>}</button>
             
              
              <button className="btn btn-danger" >{DeleteForeverIcon && <DeleteForeverIcon type={DeleteForeverIcon}/>}</button>
              </td>

            </tr>
          </tbody>
        </Table>
      </div>
    </div>
        </div>
        
    </div>
   
  )
}

export default ManageMemberData