import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// import VerifiedIcon from '@mui/icons-material/Verified';
// import { Button } from 'react-bootstrap'
// import GoogleIcon from '@mui/icons-material/Google';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import React, { useState } from 'react';

const card ={
    padding: 50
 
}

function SignupData() {
    const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <Card style={card} className="text">
      <Card.Body >
      <h3><b>Create a new account</b></h3>
      <Card.Text>
      <p style={{fontSize: '13px',}}>Use your email to create a new account</p>
      <p style={{fontSize: '13px',}}> Already have an account?{" "}
      <a href={'http://localhost:3001/login'} ><b>Login</b></a> </p>

        </Card.Text>
      {/* <Button variant="danger"> {GoogleIcon && <GoogleIcon type={GoogleIcon}/>} Login with Google</Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary"> {LinkedInIcon  && <LinkedInIcon  type={LinkedInIcon }/>} Login with LinkedIn </Button> */}
<hr style={{marginBottom: '40px'}}></hr>
        {/* <Card.Title>Thank you</Card.Title>
        <Card.Text>
        You Are Just One Click Away
        </Card.Text> */}

        {/* <Button variant="primary">Submit</Button> */}

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>First name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="First name"
            // defaultValue="Mark"
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Last name"
            // defaultValue="Otto"
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
     
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom03">
          <Form.Label>Email-Id</Form.Label>
          <Form.Control type="text" placeholder="Email-Id" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid Email.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom03">
          <Form.Label>Password</Form.Label>
          <Form.Control type="text" placeholder="Password" required />
          <Form.Control.Feedback type="invalid">
            Please provide Password.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3">
        <Form.Check required label="Agree to terms and conditions" feedback="You must agree before submitting." feedbackType="invalid"/>
      </Form.Group>
      <Button type="submit">Submit form</Button>
    </Form>

      </Card.Body>
      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
    </Card>
  );
}

export default SignupData;