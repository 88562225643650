import React from 'react'
import TextField from "@mui/material/TextField";
// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const QueriesData = () => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <div>
       <>
            <TextField sx={{ width: '50%' }} label="Search..." id="fullWidth" /> {SearchIcon && <SearchIcon type={SearchIcon} sx={{ fontSize: '45px', bgcolor: '#77065F', mt: .5, p: 1, color: '#fff', cursor: 'pointer' }} />}
            <br />
            <Card sx={{ minWidth: '275', mt: 5, bgcolor: '#0F111D', color: '#fff' }}>
                <CardContent>
                    <div className='row m-0 mb-12'>
                        <div className='col-md-6'>
                            <Typography variant="h5" component="div">
                                {AccountCircleIcon && <AccountCircleIcon type={AccountCircleIcon} sx={{ fontSize: '50px' }} />} Shashank
                            </Typography>
                        </div>
                        <div className='col-md-6'>
                            <p className='queries'>1 year Ago</p>
                        </div>
                    </div>
                    <div className='row m-0 mb-12'>

                        <div className='col-md-6'>
                            <Typography component="div" sx={{ ml: 5 }}>
                                <span>What is marketing?</span>
                            </Typography>
                        </div>
                        <div className='col-md-6'>
                            <p className='queries1' onClick={handleOpen}>Reply</p>
                            <p className='queries2'>{ThumbUpAltIcon && <ThumbUpAltIcon type={ThumbUpAltIcon} className='forum' sx={{ fontSize: '15px', ml: '0', }} />} Like </p>
                            <p className='queries'> 0 Likes </p>

                        </div>
                    </div>
                </CardContent>

            </Card>

            {/* Card 2 */}
            <Card sx={{ minWidth: '275', mt: 5, bgcolor: '#0F111D', color: '#fff' }}>
                <CardContent>
                    <div className='row m-0 mb-12'>
                        <div className='col-md-6'>
                            <Typography variant="h5" component="div">
                                {AccountCircleIcon && <AccountCircleIcon type={AccountCircleIcon} sx={{ fontSize: '50px' }} />} Ishan
                            </Typography>
                        </div>
                        <div className='col-md-6'>
                            <p className='queries'>1 year Ago</p>
                        </div>
                    </div>
                    <div className='row m-0 mb-12'>

                        <div className='col-md-6'>
                            <Typography component="div" sx={{ ml: 5 }}>
                                <span>What is marketing?</span>
                            </Typography>
                        </div>
                        <div className='col-md-6'>
                            <p className='queries1' onClick={handleOpen}>Reply</p>
                            <p className='queries2'>{ThumbUpAltIcon && <ThumbUpAltIcon type={ThumbUpAltIcon} className='forum' sx={{ fontSize: '15px', ml: '0', }} />} Like </p>
                            <p className='queries'> 0 Likes </p>

                        </div>
                    </div>
                </CardContent>

            </Card>
            {/* End of card 2 */}

            {/* Card 3 */}
            <Card sx={{ minWidth: '275', mt: 5, bgcolor: '#0F111D', color: '#fff' }}>
                <CardContent>
                    <div className='row m-0 mb-12'>
                        <div className='col-md-6'>
                            <Typography variant="h5" component="div">
                                {AccountCircleIcon && <AccountCircleIcon type={AccountCircleIcon} sx={{ fontSize: '50px' }} />} Deepanshu
                            </Typography>
                        </div>
                        <div className='col-md-6'>
                            <p className='queries'>1 year Ago</p>
                        </div>
                    </div>
                    <div className='row m-0 mb-12'>

                        <div className='col-md-6'>
                            <Typography component="div" sx={{ ml: 5 }}>
                                <span>What is marketing?</span>
                            </Typography>
                        </div>
                        <div className='col-md-6'>
                            <p className='queries1' onClick={handleOpen}>Reply</p>
                            <p className='queries2'>{ThumbUpAltIcon && <ThumbUpAltIcon type={ThumbUpAltIcon} className='forum' sx={{ fontSize: '15px', ml: '0', }} />} Like </p>
                            <p className='queries'> 0 Likes </p>

                        </div>
                    </div>
                </CardContent>

            </Card>

            {/* End of Card 3 */}

            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {AccountCircleIcon && <AccountCircleIcon type={AccountCircleIcon} sx={{ fontSize: '50px' }} />} Deepanshu
                    </Typography>
                    
                        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '45ch',ml: 7 },}}
                            noValidate
                            autoComplete="off">
                            <div>
                                <TextField id="outlined-multiline-static" label="Reply" multiline rows={4}
                                    placeholder="Type Your Message Here..." />
                            </div>
                           <center> <button className='btn btn-primary'>Reply</button></center>
                        </Box>
                </Box>
            </Modal>
            {/* End of Modal */}

        </>
    </div>
  )
}

export default QueriesData
