import React,{useState,useEffect} from 'react'
import TabPanel from '@mui/lab/TabPanel';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormGroup from '@mui/material/FormGroup';
import API from '../../Route/api';
import Swal from 'sweetalert2';

const companyname = {
    color: '#000',
    fontSize: 10
}

const Profile = () => {
    const [data,setData] = useState([]);
    useEffect(() => {
        initiateData();
      }, [])
    
      const initiateData=async()=>{
        API.get('/profile').then(res=>{
          setData(res.data.data);
          
        })    
      }

      const handleDataChange = (e) => {
        setData(
          prevState => ({
              ...prevState,
              [e.target.name]:e.target.value
          })
        );
      }

      const validateUserInput = () =>{
        var company_name= document.getElementById("company_name").value;
        var company_email_id= document.getElementById("company_email_id").value;
        var company_app_link= document.getElementById("company_app_link").value;
        var gst_no= document.getElementById("gst_no").value;
        var pan_no= document.getElementById("pan_no").value;
        var company_address= document.getElementById("company_address").value;
        var company_phone_number= document.getElementById("company_phone_number").value;
        var company_name_validation = false;
        var company_email_id_validation = false;
        var company_app_link_validation = false;
        var gst_no_validation = false;
        var pan_no_validation = false;
        var company_address_validation = false;
        var company_phone_number_validation = false;
       
        var validation = false;
  
  
        if(company_name === '' || company_name === null || company_name === undefined){
            document.getElementById("company_name-err").innerHTML = "Company name can't Be Empty!"
            company_name_validation = false;
        }else{
            document.getElementById("company_name-err").innerHTML = ''
            company_name_validation = true;
        }
  
        if(company_email_id === '' || company_email_id === null || company_email_id === undefined){
            document.getElementById("company_email_id-err").innerHTML = "Company Email can't be Empty!"
            company_email_id_validation = false;
        }else{
            document.getElementById("company_email_id-err").innerHTML = ''
            company_email_id_validation = true;
        }
  
        if(company_app_link === '' || company_app_link === null || company_app_link === undefined){
          document.getElementById("company_app_link-err").innerHTML = "Web Site Link can't be Empty"
          company_app_link_validation = false;
        }else{
          document.getElementById("company_app_link-err").innerHTML = ''
          company_app_link_validation = true;
        }
  
        if(gst_no === '' || gst_no === null || gst_no === undefined){
          document.getElementById("gst_no-err").innerHTML = "Company GST no can't be Empty"
          gst_no_validation = false;
        }else{
          document.getElementById("gst_no-err").innerHTML = ''
          gst_no_validation = true;
        }

        if(pan_no === '' || pan_no === null || pan_no === undefined){
            document.getElementById("pan_no-err").innerHTML = "PAN No can't be Empty!"
            pan_no_validation = false;
        }else{
            document.getElementById("pan_no-err").innerHTML = ''
            pan_no_validation = true;
        }

        if(company_address === '' || company_address === null || company_address === undefined){
            document.getElementById("company_address-err").innerHTML = "Company Address can't be Empty!"
            company_address_validation = false;
        }else{
            document.getElementById("company_address-err").innerHTML = ''
            company_address_validation = true;
        }

        if(company_phone_number === '' || company_phone_number === null || company_phone_number === undefined){
            document.getElementById("company_phone_number-err").innerHTML = "Phone No. can't be Empty!"
            company_phone_number_validation = false;
        }else{
            document.getElementById("company_phone_number-err").innerHTML = ''
            company_phone_number_validation = true;
        }
  
        if(company_name_validation === true && company_email_id_validation === true && company_app_link_validation === true && gst_no_validation === true && pan_no_validation === true && company_address_validation === true && company_phone_number_validation === true){
            validation = true;
        }else{
            validation = false;
        }
        return validation;
      }

      const handleSubmit= (e) => {
        e.preventDefault();
        if(validateUserInput()===true){
          API.post('auth/user/update',data).then(res=>{
            if(res.data.code===200){
              Swal.fire({
                title: res.data.status,
                text: res.data.message,
                icon: 'success',
                confirmButtonText: 'OK'
              }).then((result) => {
                window.location.reload();
              });
            }      
          }).catch(err=>{
            if(err.code==='ERR_BAD_REQUEST'){
              Swal.fire({
                title: err.response.data.status,
                text: err.response.data.message,
                icon: 'error',
                confirmButtonText: 'OK'
              })
            } 
          }); 
        } 
      };


    return (
        <div>
            {/* Profile */}
            <TabPanel value="1">
                <div class="container">
                    <div class="row">

                        {/* Company Logo */}
                        <center>
                            <div className=" col-sm-5 col-md-6 ">
                                <div className="box profilepage">
                                    <IconButton sx={{ border: 1, padding: 5 }} color="primary" aria-label="upload picture" component="label">
                                        <input hidden accept="image/*" type="file" />
                                        <PhotoCamera />
                                    </IconButton>
                                    <h6 style={companyname}>Upload Company Logo</h6>
                                </div>
                            </div>
                        </center>
                        {/* End of Company Logo */}

                        {/* Profile Details */}
                        <div className="col-sm-7 col-md-10 mt-2 p-10 profil">
                            <div className="box profilepage">
                                {/* <Form.Label htmlFor="basic-url">Your vanity URL</Form.Label> */}
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon3">
                                        Company Name
                                    </InputGroup.Text>
                                    <Form.Control aria-describedby="basic-addon3" id="company_name" name="company_name" value = {data.company_name}  onChange={handleDataChange}/>
                                    <div className="text-danger" id="company_name-err"></div>
                                </InputGroup>

                                {/* <Form.Label htmlFor="basic-url">Your vanity URL</Form.Label> */}
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon3">
                                        Company Email
                                    </InputGroup.Text>
                                    <Form.Control aria-describedby="basic-addon3"  id="company_email_id" name="company_email_id" value = {data.company_email_id} onChange={handleDataChange}/>
                                    <div className="text-danger" id="company_email_id-err"></div>
                                </InputGroup>

                                {/* <Form.Label htmlFor="basic-url">Your vanity URL</Form.Label> */}
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon3">
                                        Website/App Link
                                    </InputGroup.Text>
                                    <Form.Control  aria-describedby="basic-addon3" id="company_app_link" name="company_app_link" value = {data.company_app_link} onChange={handleDataChange}/>
                                    <div className="text-danger" id="company_app_link-err"></div>
                                </InputGroup>

                                {/* <Form.Label htmlFor="basic-url">Your vanity URL</Form.Label> */}
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon3">
                                        GST no.
                                    </InputGroup.Text>
                                    <Form.Control aria-describedby="basic-addon3" id="gst_no" name="gst_no" value = {data.gst_no} onChange={handleDataChange}/>
                                    <div className="text-danger" id="gst_no-err"></div>
                                </InputGroup>

                                {/* <Form.Label htmlFor="basic-url">Your vanity URL</Form.Label> */}
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon3">
                                        PAN no.
                                    </InputGroup.Text>
                                    <Form.Control  aria-describedby="basic-addon3" id="pan_no" name="pan_no" value = {data.pan_no} onChange={handleDataChange}/>
                                    <div className="text-danger" id="pan_no-err"></div>
                                </InputGroup>

                                {/* <Form.Label htmlFor="basic-url">Your vanity URL</Form.Label> */}
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon3">
                                        Address
                                    </InputGroup.Text>
                                    <Form.Control  aria-describedby="basic-addon3" id="company_address" name="company_address"  value = {data.company_address} onChange={handleDataChange}/>
                                    <div className="text-danger" id="company_address-err"></div>
                                </InputGroup>

                                {/* <Form.Label htmlFor="basic-url">Your vanity URL</Form.Label> */}
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon3">
                                        Phone No.
                                    </InputGroup.Text>
                                    <Form.Control  aria-describedby="basic-addon3" id="company_phone_number" name="company_phone_number" value = {data.company_phone_number} onChange={handleDataChange}/>
                                    <div className="text-danger" id="company_phone_number-err"></div>
                                </InputGroup>

                                <FormGroup>
                                    <div class="tacbox">
                                        <input id="checkbox" type="checkbox" /> &nbsp;
                                        <label for="checkbox"> I agree to these <a href="/profile">Terms and Conditions</a>.</label>
                                    </div>
                                </FormGroup>

                                <button className='btn btn-primary mt-2' onClick={handleSubmit}>Update Profile</button>
                            </div>
                        </div>
                        {/* End of  Profile Details */}
                    </div>
                </div>
            </TabPanel>
            {/* End of Profile */}
        </div>
    )
}

export default Profile
