import React,{useEffect,useState} from 'react';
import ActivityStatus from './ActivityStatus';
import Notification from './Notification';
import ProjectBox from './ProjectBox';
import "../../App.css"
import QueriesDash from './QueriesDash';
import API from '../../Route/api';

function Main() {
  const [data,setData] = useState([]);

  useEffect(() => {
    initiateData();
  }, [])

  const initiateData=async()=>{
    API.get('/dashboard').then(res=>{
      setData(res.data.data);
    })    
  }

  const heading={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  }
  const contain ={
    padding: 20,
    marginLeft: 30,
  }
    return (
        <div className="row contain" style={contain}>
        <h4 style={heading} className="head">Dashboard</h4>
        <div className='row m-0'>
        <div className='col-md-4 mb-2'>
          <ProjectBox project_count={data?.project_count}/>
        </div>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
        <div>
          <Notification job_count={data?.job_count}/>
        </div>
</div>

        </div>
        {/* <div>
          <QueriesDash />
        </div> */}
        <div>
          <ActivityStatus activity_status={data?.activity_status} />
        </div>

      </div> 
    )
}

export default Main
