import React from 'react'
import PostNewJobData from '../PostNewJob/PostNewJobData'
import { Button } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CKEditor from "react-ckeditor-component";
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TagsInput } from "react-tag-input-component";
import dayjs from 'dayjs';
import { useState } from "react";
import API from '../../../Route/api';
import Swal from 'sweetalert2';


function PostNewJobMain() {
    const [open, setOpen] = React.useState(false);
    const [jobs, setJobs] = React.useState([]);
    const [value, setValue] = React.useState(0);
    const [selected, setSelected] = useState();
    const [visible, setVisible] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        console.log(e)
        console.log(jobs)
        setJobs(
            prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            })
        );
    }

    const handleTagsChange = (e,name) =>{
        setJobs(
            prevState => ({
                ...prevState,
                [name]: e
            })
        );
    }

    const handleCKEditor = (e, name) => {
        console.log(e,name)
        setJobs(
          prevState => ({
            ...prevState,
            [name]: e.editor.getData()
          }));
    }

    const handleAutoCompleteCheckbox = (event, value, name) => {
        console.log(jobs)
        setJobs(
          prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleRadioChange = (e)=>{ 
        console.log(e.target.name)
        console.log(e.target.value)
        setJobs(
            prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            })
        );
        if(e.target.value === 'internship'){
            setVisible(true)
        }else{
            setVisible(false)
        }
    }

    const handleDateChange = (date, name) => {
        console.log(jobs)
        setJobs(
            prevState => ({
            ...prevState,
            [name]: date
        }));
    }

    const handleSubmit = async (e) => {
        console.log(jobs)
        e.preventDefault()

        API.post('company/jobs', jobs).then(res => {
            if (res.data.code === 200) {
                setOpen(false);
                Swal.fire({
                    title: res.data.status,
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {                    
                    window.location.href = "/postnewjob";
                })
            }
        }).catch(err => {
            if (err.code === 'ERR_BAD_REQUEST') {
                Swal.fire({
                    title: err.response.data.status,
                    text: err.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            }
        })
    }
    const heading = {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
    }
    const contain = {
        padding: 20,
        marginLeft: 30,
    }

    const style = {
        position: 'absolute',
        top: '125%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll'
    };

    const p = {
        marginTop: 10,
    }

    const ckeditor = {
        minHeight: 500,
    }
    return (
        <div style={contain} className="contain">
            <h4 style={heading} className="mb-4">Job's</h4>
            <div>
                <div className='mb-4'>
                    {/* <Button variant="primary"> {AddIcon && <AddIcon type={AddIcon} />} Post New Job</Button> */}

                    <div>
                        <Button onClick={handleOpen}>{AddIcon && <AddIcon type={AddIcon} />}Post New Job</Button>
                        <div>


                            <Modal className='model' open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                                <Box className='model' sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        <div>
                                            <p><b>Job Profile</b></p>
                                            <TextField fullWidth label="Job Profile" id="fullWidth" value={jobs?.company_project_type} name="job_name" onChange={handleChange} />
                                            <p style={p}><b>Job Discription</b></p>
                                            <CKEditor style={ckeditor} className="ckeditor" initData={<p>This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React.</p>} content={jobs?.job_description} name="job_description" events={{ 'change': (event) => handleCKEditor(event, 'job_description') }} />

                                            {/* Redio Button */}

                                            {/* Onclick */}

                                            <div className="form-group row">
                                                <FormLabel style={p} id="demo-row-radio-buttons-group-label"><b>Job Type:</b></FormLabel>
                                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={jobs?.worktype} onChange={ handleRadioChange} >
                                                    <FormControlLabel type="radio" className="mx-2 mt-1" name="worktype" value="fulltime"  control={<Radio />} label="Full-Time" />
                                                    <FormControlLabel type="radio" className="mx-2 mt-1" name="worktype" value="parttime" control={<Radio />} label="Part-Time" />
                                                    <FormControlLabel type="radio" className="mx-2" name="worktype" value="internship"  control={<Radio />} label="Internship" />
                                                </RadioGroup>
                                                {visible &&
                                                    <div className="form-group row">
                                                        <div className="col-sm-6">
                                                            {/* <textarea type="text" className="form-control" id="msg" name="msg" placeholder='Duration' /> */}
                                                            <TextField fullWidth label="Duration" id="fullWidth" name="duration" value={jobs?.duration} onChange={handleChange}/>
                                                        </div>
                                                    </div>
                                                }
                                                {/* End of OnClick */}

                                                <TextField fullWidth label="Location" id="fullWidth" name="job_location" value={jobs?.job_location} onChange={handleChange}/>

                                                {/* Date Piker */}
                                                <p style={p}><b>Closing Date:</b></p>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        defaultValue={dayjs('2022-04-17T15:30')}
                                                        onChange={(date) => { handleDateChange(new Date(date).toLocaleString("en-US"), 'closing_date') }}
                                                        value={jobs?.closing_date}
                                                        label="Basic example"                                                       
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                {/* End  */}

                                                <p style={p}><b>Eligibility Criteria</b></p>
                                                <CKEditor className="ckeditor" initData={<p>This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React.</p>} content={jobs?.eligiblity} name="eligiblity" events={{ 'change': (event) => handleCKEditor(event, 'eligiblity') }} />

                                            </div>
                                            {/* End Radio Button */}

                                            <div>
                                                <p style={p}><b>Skill's Required</b></p>
                                                <TagsInput  placeHolder="Enter" name="skills" value={jobs?.skills} onChange={(event)=>handleTagsChange(event,'skills')}/>
                                            </div>

                                            <p style={p}><b>Job Role & Responsibilities</b></p>
                                            <CKEditor className="ckeditor" initData={<p>This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React.</p>} content={jobs?.roles} name="roles" events={{ 'change': (event) => handleCKEditor(event, 'roles') }} />


                                            <TextField style={p} fullWidth label="No. of Vacencies" id="fullWidth" name="no_of_vaccancy" value={jobs?.no_of_vaccancy} onChange={handleChange}/>

                                            <TextField style={p} fullWidth label="CTC(LPA)" id="fullWidth" name="stipend" value={jobs?.stipend} onChange={handleChange}/>

                                        </div>
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        <Button onClick={handleSubmit}>Post</Button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button onClick={handleOpen}>Cancle</Button>
                                    </Typography>
                                </Box>
                            </Modal>
                        </div>
                    </div>

                    {/* <button style={button} type="button" class="btn btn-primary btn-lg">Submit</button> */}
                </div>
                <PostNewJobData />
            </div>
        </div>

    )
}

export default PostNewJobMain