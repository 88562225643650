import React from 'react'
import ManageMemberButton from '../Control/ManageMemberButton'
import ManageMemberData from '../Control/ManageMemberData'
// import "../../App.css"

function ManageMemberMain() {
    const heading={
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
      }
      const contain ={
        padding: 20,
        marginLeft: 30,
      }
  return (
    <div style={contain} className="contain">
        <h4 style={heading} className="mb-4">Manage Members</h4> 
        <div>
          <ManageMemberButton />
        </div>
        <div>
          <ManageMemberData />
        </div>
    </div>
   
  )
}

export default ManageMemberMain