import Table from 'react-bootstrap/Table';
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import AllProjectModalTable from './AllProjectModalTable';
import API from '../../Route/api';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import { Button } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CKEditor from "react-ckeditor-component";
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TagsInput } from "react-tag-input-component";
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';



function AllProjectData(props) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [region, setRegions] = React.useState([]);
  const [skills, setSkills] = React.useState([]);

  const [targetAgeGroup, settargetAgeGroup] = React.useState([]);



  const [showModal, setShow] = useState(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    initiateData();
  }, [])

  const initiateData = async () => {
    API.get('/projects').then(res => {
      setData(res.data.data);

    })
  }
  const getStatus = (status) => {
    var text = '';
    switch (parseInt(status)) {
      case 1: text = "On Going";
        break;
      case 0: text = "Completed";
        break;
      default: text = "TBD";
    }
    return text;
  }

  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  }
  const contain = {
    padding: 20,
    marginLeft: 30,
  }

  const style = {
    position: 'absolute',
    top: '125%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    // width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
    marginTop: 55
  };

  const p = {
    marginTop: 10,
  }

  const ckeditor = {
    // minHeight: 50,
  }

  const ck = {
    marginTop: 30,
    width: '50%'
  }

  const demographics = {
    marginTop: 30
  }
  const demographics2 = {
    marginTop: 60
  }
  const card = {
    padding: 50

  }

  const workshoptopic = {
    marginTop: 30
  }

  const icon = {
    fontSize: 70,
    color: '#8D0956',
    paddingBottom: 10,
  }
  return (
    <>
      <div
      >
        {/* Modal Start */}
        <div>
          <div className='mb-4'>
            {/* <Button variant="primary"> {AddIcon && <AddIcon type={AddIcon} />} Post New Job</Button> */}

            <div>
              {/* <Button onClick={handleOpen}>{AddIcon && <AddIcon type={AddIcon} />}Post New Job</Button> */}
              <div>


                <Modal className='model' open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                
                  <Box className='model' sx={style}>
                  <div className='d-flex justify-content-center m-1 mt-3'>

<h5 style={{fontWeight: 800}}>Requirements</h5>
</div>
                    <div>
                      <p><b>Project Type*</b></p>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label=""
                          name="company_project_type"
                        >
                          <MenuItem value={'Business'}>Business</MenuItem>
                          <MenuItem value={'Technical'}>Technical</MenuItem>
                          <MenuItem value={'Marketing'}>Marketing</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <TextField sx={{ marginTop: '30px', marginBottom: '30px' }} fullWidth label="Task Type *" id="fullWidth" name="company_task" />

                    <p><b>Instructions (Do's & Don'ts) *</b></p>
                    <CKEditor initData={<p>This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React.</p>} name="company_project_instruction" />

                    <p style={ckeditor}><b>Your Task (Mention in bullet points) *</b></p>
                    <CKEditor initData={<p>This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React.</p>} name="company_project_description" />

                    <Box style={ck}
                      component="form"
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '50ch' },
                      }}
                      noValidate
                    // autoComplete="off"
                    >
                      <div>
                        <FormLabel id="demo-row-radio-buttons-group-label"><b>Do You Have Any Incentive Model? *</b></FormLabel>

                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                          <FormControlLabel value="No" control={<Radio />} label="No" />

                        </RadioGroup>


                      </div>

                    </Box>

                    <p style={ckeditor}><b>Link For Steps To Be Followed *</b></p>
        <TextField className='mb-2' fullWidth label="Youtube Link *" id="fullWidth"  name="company_project_youtube_link"  />

        <p style={ckeditor}><b>Start Date *</b></p>
        {/* <DateEndPicker/> */}
        <Stack component="form" noValidate spacing={3}>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            defaultValue="2017-05-24"
            sx={{ width: 220, marginBottom: 1 }}
            InputLabelProps={{
              shrink: true,
            }}
            name="company_project_start_date"
          />
        </Stack>
        <p style={ckeditor}><b>Expected End Date *</b></p>
        <TextField fullWidth label="Enter The Expected End Date *" id="fullWidth"  name="company_project_expected_end_date"  />

        <div className='d-flex justify-content-center m-1 mt-3'>

<h5 style={{fontWeight: 800}}>Demographics</h5>
</div>
        <div>
          <p><b>Select Region *</b></p>
          {/* <Demographics /> */}
          <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={region}
            name="company_project_regions"
            renderInput={(params) => (
              <TextField {...params}
                sx={{ width: '100%' }}

              />

            )}
          // sx={{ width: '500px' }}
          />
        </div>

        <div style={demographics}>
          <FormLabel id="demo-row-radio-buttons-group-label"><b>Gender *  </b></FormLabel>

          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">

            <FormControlLabel value="Male" name="company_project_gender" control={<Radio />} label="Male" />

            <FormControlLabel value="Female" name="company_project_gender"  control={<Radio />} label="Female" />
          </RadioGroup>

        </div>

        <div style={demographics}>
          <p><b>Market Segment Status *</b></p>
        <CKEditor initData={<p>This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React.</p>}  name="company_project_instruction"  />
        </div>

        <div style={demographics2}>
          <p><b>Target Age Group *</b></p>
          <Autocomplete
            multiple
            limitTags={2}
            id="multiple-limit-tags"
            options={targetAgeGroup}
            name="company_project_target_age_group"
            renderInput={(params) => (
              <TextField {...params}
                sx={{ width: '100%' }}

              />

            )}
          />

        </div>
        
 {/* <YourDeliverables /> */}
 <div className='d-flex justify-content-center m-1 mt-3'>

 <h5 style={{fontWeight: 800}}>Your Deliverables</h5>
 </div>

          <div>
            <p><b>Project Type*</b></p>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label=""
                name="company_project_workshop_type"
              >
                <MenuItem value="Business">Business</MenuItem>
                <MenuItem value="Technical">Technical</MenuItem>
                <MenuItem value="Marketing">Marketing</MenuItem>
              </Select>
            </FormControl>
          </div>

          <p style={workshoptopic}><b>Project Topic*</b></p>
          <TextField fullWidth id="outlined-basic" placeholder="Workshop Topic*" name="company_project_workshop_topic"  />

          <p style={workshoptopic}><b>Date & Time *</b></p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              defaultValue={dayjs('2022-04-17T15:30')}
              renderInput={(props) => <TextField {...props} />}
              label="DateTimePicker"


            />
          </LocalizationProvider>


          <p style={workshoptopic}><b>Project Description*</b></p>
          <TextField fullWidth id="outlined-multiline-static" multiline rows={4} placeholder="Workshop Description*"  name="company_project_workshop_description"  />

          <p style={workshoptopic}><b>Skill Gained*</b></p>
          <Autocomplete multiple id="checkboxes-tags-demo" options={skills} disableCloseOnSelect  renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon}  style={{ marginRight: 8 }} checked={selected} />
              {option.name}
            </li>
          )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField {...params} label="Skill Gained" placeholder="Favorites" />
            )}
          />


          <p style={workshoptopic}><b>Merchandise/Insentives For Top Performers(if any)</b></p>
          <TextField fullWidth id="outlined-basic" placeholder="Merchandise For Top Performers(if any)"  name="company_project_workshop_merch"  />

     






                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <Button >Post</Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button onClick={handleOpen}>Cancle</Button>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>

            {/* <button style={button} type="button" class="btn btn-primary btn-lg">Submit</button> */}
          </div>
        </div>
        {/* Modal End */}
        <Table bordered hover>
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Task Type</th>
              <th>Submission/Applicants</th>
              <th>Project Status</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Approved</th>
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            {data?.map((val, index) => {
              return (
                <tr>
                  <td>{val.company_name} {val.company_project_type}</td>
                  <td>{val.company_task}</td>
                  <td></td>
                  <td>{getStatus(val.status)}</td>
                  <td>{val.company_project_start_date}</td>
                  <td>{val.company_project_expected_end_date}</td>
                  <t></t>
                  <td>
                    <Button onClick={handleOpen} style={{ marginRight: 5 }}>
                      <EditIcon />
                    </Button>
                    <Button onClick={() => {
                      window.location.pathname = "/view"
                    }}>
                      <PreviewIcon />
                    </Button>
                  </td>
                </tr>
              )
            })}

          </tbody>
        </Table>

      </div>
      {/* <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <AllProjectModalTable />
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default AllProjectData;