import React from 'react'
import styled from "styled-components";
import LightbulbIcon from '@mui/icons-material/Lightbulb';


function ManageMembers() {
    const Box = styled.div`
    color: #fff;
  `
    const Contain = styled.div`
  margin-top: 30px;  
  padding: 20px;
    ${'' /* background-color: #2b3844; */}
    background-color: #28313d;
    ${'' /* width: max-content; */}
  `
    const InnerBox = styled.div`
    background-color: #303d48;
    padding: 5px;

  `
    const Total = styled.div`
  padding: 1px;
  `
    const box = {
        borderLeft: `5px solid #6BA9DC`
    }
    const h6 = {
        fontWeight: 600
    }
    return (
        <Contain>
            <Box>
                <h6 style={h6}>Manage Members</h6>
                <hr></hr>
            </Box>
            <Box style={box}>
                <InnerBox>
                    <Total>
                        <div className='row m-0'>
                            <div className='col-md-4 d-flex justify-content-center align-items-center'>
                                <LightbulbIcon sx={{ fontSize: 50 }} />
                            </div>
                            <div className='col-md-8'>
                                <h5>0</h5>
                                <div onClick={() => {
                                    window.location.pathname = "/workshopmanage";
                                }}>
                                    <h6>Manage Members</h6>
                                </div>
                            </div>
                        </div>
                    </Total>
                </InnerBox>
            </Box>
        </Contain>

    )
}

export default ManageMembers