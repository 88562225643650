import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import AllProjectDropDown from './AllProjectDropDown';

function AllProjectModalTable(props) {
  return (
    <Table striped bordered hover style={{marginTop: 20}}>
      <thead>
        <tr>
        <th>#</th>
          <th>User Name</th>
          <th>User Email</th>
          <th>Key Skills</th>
          <th>Region</th>
          <th>Status</th>
          <th>Project Start Date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <td> <Form.Check
            inline
            name="group1"
          /></td>
          <td>Ishan</td>
          <td>abc@gmail.com</td>
          <th></th>
          <td>Varanasi</td>
          <td>
          <AllProjectDropDown 
            heading="Select Status"
            one="To be Started"
            two="W.I.P"
            three="Submitted"
            four="Re-submitted"
          />
          </td>
          <td>09 Feb 2023</td>
          <td>
          <AllProjectDropDown 
            heading="Action"
            one="Approve Applicant"
            two="Reject Applicant"
            three="Approve Project"
            four="Reject Project"
          />
          </td>
        </tr>
        <tr>
        <td> <Form.Check
            inline
            name="group1"
          /></td>
          <td>Ishan</td>
          <td>abc@gmail.com</td>
          <th></th>
          <td>Varanasi</td>
          <td>
          <AllProjectDropDown 
            heading="Select Status"
            one="To be Started"
            two="W.I.P"
            three="Submitted"
            four="Re-submitted"
          />
          </td>
          <td>09 Feb 2023</td>
          <td>
          <AllProjectDropDown 
            heading="Action"
            one="Approve Applicant"
            two="Reject Applicant"
            three="Approve Project"
            four="Reject Project"
          />
          </td>
        </tr> 
        <tr>
        <td> <Form.Check
            inline
            name="group1"
          /></td>
          <td>Ishan</td>
          <td>abc@gmail.com</td>
          <th></th>
          <td>Varanasi</td>
          <td>
          <AllProjectDropDown 
            heading="Select Status"
            one="To be Started"
            two="W.I.P"
            three="Submitted"
            four="Re-submitted"
          />
          </td>
          <td>09 Feb 2023</td>
          <td>
          <AllProjectDropDown 
            heading="Action"
            one="Approve Applicant"
            two="Reject Applicant"
            three="Approve Project"
            four="Reject Project"
          />
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default AllProjectModalTable;