import React, { useEffect }  from 'react'
import styled from "styled-components";
// import PersonIcon from '@mui/icons-material/Person';
// import { height } from '@mui/system';
import { Table } from "react-bootstrap";
import EditIcon from '@mui/icons-material/Edit';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import { NoEncryption } from '@material-ui/icons';
import Tooltip from '@mui/material/Tooltip';
import API from '../../Route/api'
import Swal from 'sweetalert2';


function ActiveJob() {
  
  const [jobs, setJobs] = React.useState([]);
  useEffect(() => {
    initiateData();
  }, []);
  const initiateData = () => {
    API.get('/company/jobs/dashboard').then(res => {
      console.log(res)
        setJobs(res.data.data);
    });
}
  
  const Box = styled.div`
    color: #fff;
    
  `
  const Contain = styled.div`
  margin-top: 30px;  
    padding: 20px;
    background-color: #28313d;
   
  `
  const Total = styled.div`
  padding: 1px;
  `
  const h6 = {
    fontWeight: 600
  }
  // const head = {
  //   backgroundColor: '#EEF0F6',
  //   color: '#fff',
  //   fontWeight: 600,
  // };

  const table = {
    marginTop: -2,
    color: '#fff',
    // displayBorder: 'none'
  }
  const edit = {
    cursor: 'pointer'
  }
 
  
  return (
    <Contain>
      <Box>
        <h6 style={h6}>Active Job's</h6>
        <hr></hr>
      </Box>
      <Box>
        
          <Total>
            <div className='row'>
              <div className="table-responsive-sm overflow-auto scroll" style={table}>
                <Table style={table}>
                  <tbody className="text-center align-content-center">

                    {/* {jobs.activeJobs.map((job, index)=>{
                      return( 
                        <tr>
                          <td><input type="checkbox" /> </td>
                          <td>{job.job_name}</td>                        
                          <td> 
                            <Tooltip title="Edit Job" placement="right">
                              <icon style={edit}> {EditIcon && <EditIcon type={EditIcon}  />} </icon>
                            </Tooltip>
                          </td>
                        </tr>
                      )})} */}
                  </tbody>
                </Table>
              </div>
            </div>
          </Total>
     
      </Box>
    </Contain>

  )
}

export default ActiveJob