import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Main from "./Components/HomeComponent/Main";
import Navbar from '../src/Components/NavBar/NavBar';
// import Sidebar from "./Components/Sidebar/Sidebar";
import MemberMain from "./Components/Members/MemberMain";
// import LaunchMain from "./Components/LaunchProject/LaunchMain";
import AllMain from "./Components/AllProject/AllMain";
import ControlMain from "./Components/ControlPanel/ControlMain";
import JobMain from "./Components/Jobs/JobMain";
import WorkshopMain from "./Components/ControlPanel/Control/WorkshopMain";
import ManageMemberMain from "./Components/ControlPanel/Control/ManageMemberMain";
import CreateUserRoleMain from "./Components/ControlPanel/Control/CreateUserRoleMain";
import ProjectMain from "./Components/LaunchProject/ProjectLaunch/ProjectMain";
import SignupMain from "./Components/Signup/SignupMain";
import LoginMain from "./Components/Login/LoginMain";
import PostNewJobMain from "./Components/Jobs/PostNewJob/PostNewJobMain";
import SideNew from "./Components/Sidebar/SideNew";
import ResumeMain from "./Components/Resume/ResumeMain";
import ShortListMain from "./Components/Shortlist/ShortListMain";
import ProfileMain from "./Components/Profile/ProfileMain";
import QueriesMain from "./Components/Queries/QueriesMain";
import ProcessMain from "./Components/Process/ProcessMain";
import UserSignupMain from "./Components/Signup/SignupMain";
import UserLoginMain from "./Components/Login/LoginMain";
import EditAction from "./Components/AllProject/EditAction";
import AllProjectModalTable from "./Components/AllProject/AllProjectModalTable";



function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
        <div className="row">
          <div className="col-2 col-lg-2 col-md-1 col-xs-1 side">
            {/* <Sidebar /> */}
            <SideNew />

          </div>
          <div className="col-10 col-lg-10 col-md-11 col-xs-11">
          <Route exact path="/" component={Main} />
          <Route exact path="/member" component={MemberMain} />
          <Route exact path="/projectlaunch" component={ProjectMain} />
          <Route exact path="/workshopmanage" component={ManageMemberMain} />
          <Route exact path="/project" component={AllMain} />
          <Route exact path="/control" component={ControlMain} />
          {/* <Route exact path="/jobdash" component={JobMain} /> */}
          <Route exact path="/workshops" component={WorkshopMain} />
          <Route exact path="/action" component={CreateUserRoleMain} />
          <Route exact path="/jobdetails" component={JobMain} />
          <Route exact path="/company/signup" component={SignupMain} />
          <Route exact path="/compaay/login" component={LoginMain} />
          <Route exact path="/user/signup" component={UserSignupMain} />
          <Route exact path="/user/login" component={UserLoginMain} />
          <Route exact path="/postnewjob" component={PostNewJobMain} />
          <Route exact path="/resume" component={ResumeMain} />
          <Route exact path="/shortlist" component={ShortListMain} />
          <Route exact path="/profile" component={ProfileMain} />
          <Route exact path="/queries" component={QueriesMain} />
          <Route exact path="/processLogin" component={ProcessMain}/>
          <Route exact path="/edit" component={EditAction}/>
          <Route exact path="/view" component={AllProjectModalTable}/>






          </div>

        </div>
        
        </Switch>
      </Router>
      
      
    </div>
  );
}

export default App;
