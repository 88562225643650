import React from 'react'
// import PostNewJobData from '../PostNewJob/PostNewJobData'
import { Button } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import ShortListData from './ShortListData';


function ShortListMain() {

    const heading = {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
    }
    const contain = {
        padding: 20,
        marginLeft: 30,
    }

    const button ={
        marginLeft: 5
    }
    return (
        <div style={contain} className="contain">
            <h4 style={heading} className="mb-4">Shortlist</h4>
            <div>
                <div className='mb-4'>
                    <div>
                        <row>
                            <Button style={button}>{AddIcon && <AddIcon type={AddIcon} />}Schedule Interview</Button>
                            <Button style={button}>{AddIcon && <AddIcon type={AddIcon} />}Update Status</Button>
                            <Button style={button}>{AddIcon && <AddIcon type={AddIcon} />}Send Offer letter</Button>
                            <Button style={button}>{AddIcon && <AddIcon type={AddIcon} />}Reject</Button>
                        </row>
                    </div>

                </div>
                <ShortListData />
            </div>
        </div>

    )
}

export default ShortListMain