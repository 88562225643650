import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// import VerifiedIcon from '@mui/icons-material/Verified';
// import { Button } from 'react-bootstrap'
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import React, { useState } from 'react';
// import login5 from "..//Login/login5.png";
import validator from 'validator'


function LoginData() {
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    //Password Validation 
    const validate = (value) => {

        if (validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setErrorMessage('Strong Password')
        } else {
            setErrorMessage('Password Is Not Strong')
        }
    }
    //End of Password Validation 

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    const login = {
        justifyContent: 'center',
        textAlign: 'center'
    }
    const emailpass = {
        justifyContent: 'center',
        textAlign: 'center'
    }

    return (
        <Card className="textCenter textlogin">
            <Card.Body style={login}>
                <h3><b>Login</b></h3>
                <Card.Text>

                </Card.Text>
                <Button variant="danger"> {GoogleIcon && <GoogleIcon type={GoogleIcon} />} Login with Google</Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button variant="primary"> {LinkedInIcon && <LinkedInIcon type={LinkedInIcon} />} Login with LinkedIn </Button>
                <p style={{ fontSize: '13px', marginTop: '25px' }}>or login with email address{" "} </p>
                <hr style={{ marginBottom: '40px' }}></hr>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                    </Row>

                    <Row className="mb-3" style={emailpass}>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Email-Id</Form.Label>
                            <Form.Control type="text" placeholder="Email-Id" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Email.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3" style={emailpass}>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Password</Form.Label>
                            {/* <Form.Control type="text" placeholder="Password" required /> */}

                            <Form.Control type="text" placeholder="Password" required onChange={(e) => validate(e.target.value)} />
                            <br /> {errorMessage === '' ? null : <span style={{ fontWeight: 'bold', color: 'red', }}>{errorMessage}</span>}
                            <p style={{ fontSize: '13px', marginTop: '-10px', float:'right' }}>{" "}
                        <a href={'http://localhost:3001/signup'} ><b>Forgot Password?</b></a> </p>
                            <Form.Control.Feedback type="invalid">
                                Please provide Password.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Button type="submit">LogIn</Button>
                    <p style={{ fontSize: '13px', marginTop: '15px' }}> Don't have an account?{" "}
                        <a href={'http://localhost:3001/signup'} ><b>SignUp</b></a> </p>

                        <p style={{ fontSize: '13px', marginTop: '25px' }}> <b>Note:</b> For better experience kindly view the application in Google Chrome{" "}</p>   
                </Form>

            </Card.Body>
            {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
        </Card>
    );
}

export default LoginData;