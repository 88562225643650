import React from "react";
import { Table } from "react-bootstrap";

function WorkshopTable() {
  const contain = {
    padding: 20,
    backgroundColor: "#fff",
    margin: 10,
    borderRadius: 10,
    height: "90%"
  };
  const columns = {
    color: "#6C757D",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
  };
  const head = {
    backgroundColor: "rgba(62, 125, 199, 0.2)",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  };
  const table = {
    height: "90%"
  }
  return (
    <div style={contain} className="container " >
      {/* <h3 style={heading}>Status</h3> */}
      <div className="table-responsive-sm overflow-auto scroll" style={table}>
        <Table style={{ marginRight: 10 }} className="table">
          <thead className="text-center align-content-center" style={head}>
            <tr>
              <th>#</th>
              <th>Project Type</th>
              <th>Task Type</th>
              <th>Project Status</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody className="text-center align-content-center" style={columns}>
            <tr>
              <td>1</td>
              <td>Product Promotion</td>
              <td>Marketing</td>
              <td>Ongoing</td>
              <td>24 Jan 2023</td>
              <td>30 Jan 2023</td>
            </tr>
            <tr>
              <td>2</td>
              <td>App Development</td>
              <td>Development</td>
              <td>Ongoing</td>
              <td>24 Jan 2023</td>
              <td>30 Jan 2023</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Software Testing</td>
              <td>Testing</td>
              <td>Ongoing</td>
              <td>24 Jan 2023</td>
              <td>30 Jan 2023</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Software Cracking</td>
              <td>Cracking</td>
              <td>Ongoing</td>
              <td>24 Jan 2023</td>
              <td>30 Jan 2023</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Digital Marketing</td>
              <td>Marketing</td>
              <td>Ongoing</td>
              <td>24 Jan 2023</td>
              <td>30 Jan 2023</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default WorkshopTable
