import React from 'react'
import AllProjectData from './AllProjectData'

function AllMain() {
  const heading={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  }
  const contain ={
    padding: 20,
    marginLeft: 30,
  }
  return (
    <div style={contain} className="contain">
        <h4 style={heading} className="mb-4">All Project's</h4> 
        <div>
          <AllProjectData />
        </div>
    </div>
    
  )
}

export default AllMain