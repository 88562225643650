import Dropdown from 'react-bootstrap/Dropdown';

function AllProjectDropDown(props) {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {props.heading}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">{props.one}</Dropdown.Item>
        <Dropdown.Item href="#/action-2">{props.two}</Dropdown.Item>
        <Dropdown.Item href="#/action-3">{props.three}</Dropdown.Item>
        <Dropdown.Item href="#/action-4">{props.four}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default AllProjectDropDown;