import React from 'react'
import styled from "styled-components";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';


function ProjectBox(props) {
  const Box = styled.div`
    color: #fff;
  `
  const Contain = styled.div`
    padding: 20px;
    ${'' /* background-color: #2b3844; */}
    background-color: #28313d;
    ${'' /* width: max-content; */}
  `
  const InnerBox = styled.div`
    background-color: #303d48;
    padding: 5px;

  `
  const Total = styled.div`
  padding: 1px;
  `
  return (
    <Contain>
    <Box>
        <h6>Job's</h6>
        <hr></hr>
    </Box>
    <Box>
      <InnerBox>
        <Total>
          <div className='row m-0'>
          <div className='col-md-4 d-flex justify-content-center align-items-center'>
<TextSnippetIcon sx={{ fontSize: 50 }}/>
          </div>
          <div onClick={() => {
            window.location.pathname = "/postnewjob"}} className='col-md-8'>
            <h5>{props.job_count}</h5>
            <div>

            <h6>Total Job's</h6>
            </div>
          </div>


          </div>
        </Total>
      </InnerBox>
    </Box>
    </Contain>
    
  )
}

export default ProjectBox