import React,{useEffect,useState} from 'react';
import ActiveJob from './ActiveJob';
import TotalJob from './TotalJob';
import styled from "styled-components";
import { Table } from "react-bootstrap";
import API from '../../Route/api'
import Swal from 'sweetalert2';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import "../../App.css"

function JobMain() {
  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  }
  const contain = {
    padding: 20,
    marginLeft: 30,
  }
  const [activeJobs, setActiveJobs] = React.useState([]);
  const [totalJobs, setTotalJobs] = React.useState([]);
  useEffect(() => {
    initiateData();
  }, []);
  const initiateData = () => {
    API.get('/company/jobs/dashboard').then(res => {
      console.log(res)
      setActiveJobs(res.data.data.activeJobs);
      setTotalJobs(res.data.data.totalJobs);
    });
}
  
  const Box = styled.div`
    color: #fff;
    
  `
  const Contain = styled.div`
  margin-top: 30px;  
    padding: 20px;
    background-color: #28313d;
   
  `
  const Total = styled.div`
  padding: 1px;
  `
  const h6 = {
    fontWeight: 600
  }

  const table = {
    marginTop: -2,
    color: '#fff',
 
  }
  const edit = {
    cursor: 'pointer'
  }
 
    const InnerBox = styled.div`
    background-color: #303d48;
    padding: 5px;

  `
  
    const box = {
        borderLeft: `5px solid #6BA9DC`
    }
   
    const tillnow = {
        fontSize: 12
    }
  return (
    <div className="row contain" style={contain}>
      <h4 style={heading} className="head">Job's Dashboard</h4>
      <div className='row m-0'>
        <div className='col-md-6'>
        <Contain>
          <Box>
            <h6 style={h6}>Active Job's</h6>
            <hr></hr>
          </Box>
          <Box>
            
              <Total>
                <div className='row'>
                  <div className="table-responsive-sm overflow-auto scroll" style={table}>
                    <Table style={table}>
                      <tbody className="text-center align-content-center">

                        {activeJobs.map((job, index)=>{
                          return( 
                            <tr>
                              <td><input type="checkbox" /> </td>
                              <td>{job.job_name}</td>                        
                              <td> 
                                <Tooltip title="Edit Job" placement="right">
                                  <icon style={edit}> {EditIcon && <EditIcon type={EditIcon}  />} </icon>
                                </Tooltip>
                              </td>
                            </tr>
                          )})}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Total>
        
          </Box>
        </Contain>
        </div>
        {/* <div className='col-md-4'></div> */}
        <div className='col-md-6'>
          <div>
          <Contain>
            <Box>
                <h6 style={h6}>Total Job's</h6>
                <hr></hr>
            </Box>
            <Box style={box}>
                <InnerBox>
                    <Total>
                        <div className='row m-0'>
                            <div className='col-md-4 d-flex justify-content-center align-items-center'>
                                {/* <WorkIcon sx={{ fontSize: 40}} /> */}
                                <h1>{totalJobs}</h1>
                            </div>
                            <div className='col-md-8'>
                                <h6>Total Job's Available</h6>
                                    <p style={tillnow}> <DateRangeIcon sx={{ fontSize: 13}} /> Till Now</p>
                            </div>
                        </div>
                    </Total>
                </InnerBox>
            </Box>
        </Contain>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobMain
